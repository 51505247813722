import React, { Fragment } from 'react'

import { Link } from 'gatsby'

import IconLink from './../components/iconLink/IconLink'
import HalfImage from './../components/halfImage/HalfImage'
import Layout from './../components/layout'

//Images
import headerImage from './../images/header/john-ashley-classic-car-specialist.jpg'
import leftImage from './../images/main/spanners.png'

//Icons
import tvr from './../images/icons/tvr-img.png'
import lotus from './../images/icons/lotus-img.png'
import alpine from './../images/icons/alpine-img.png'

const IndexPage = () => (
  <Layout>
    <div className="home">
      <div
        className="header-image"
        style={{ backgroundImage: `url(${headerImage})` }}
      >
        <div className="container header-text">
          <div className="row">
            <div className="seven columns">
              <h1>
                A sentence that explains who we are and what we can do for our
                customers.
              </h1>
              <h5>From services and MOT’s to rebuilds and race preperation.</h5>
              <div className="header-image-buttons">
                <Link to="/contact">
                  <button className="button-primary">Contact Us</button>
                </Link>
                <Link to="/services">
                  <button className="button-quaternary">
                    See our services
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <HalfImage
        image={leftImage}
        background
        imagePosition="right"
        text={
          <Fragment>
            <h2>What We Do</h2>
            <p>
              Some copy about what we do and our services ipsum dolor sit amet,
              consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua. Ut enim ad minim veniam, quis
              nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
              consequat. Duis aute irure dolor in reprehenderit in voluptate
              velit esse cillum dolore.
            </p>
            <Link to="/services">
              <button className="button-secondary">
                Full list of Services
              </button>
            </Link>
          </Fragment>
        }
      />
      <div className="full-primary-light">
        <div className="container ">
          <div className="row ">
            <div className="twelve columns">
              <h3 className="center-text">We are specialists</h3>
              <p className="center-text">
                Copy about being specialists in TVR, Lotus and Renault Alpine -
                but making it clear that you can work on other cars too. Lorem
                ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="four columns">
              <IconLink icon={tvr} title="TVR Guides" link="/resources/tvr" />
            </div>
            <div className="four columns">
              <IconLink
                icon={lotus}
                title="Lotus Guides"
                link="/resources/lotus"
              />
            </div>
            <div className="four columns">
              <IconLink
                icon={alpine}
                title="Alpine Guides"
                link="/resources/alpine"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
